<template>
  <span>
    <confirm-send-dialog ref="confirmSendDialogRef" />
    <missing-email-dialog ref="missingEmailDialogRef" />
    <v-btn
      v-if="isButtonVisible"
      outlined min-width="34"
      max-width="34"
      @click="onSendReportClick"
    >
      <v-img
        :src="require('@/assets/excel_icon.svg')"
        max-height="24"
        max-width="24"
        contain
      />
    </v-btn>
  </span>
</template>
<script>
import ConfirmSendDialog from '@/components/broken_items/report/ConfirmSendDialog.vue'
import MissingEmailDialog from '@/components/broken_items/report/MissingEmailDialog.vue'

import { isString } from '@/helpers/Utils.js'

import useAuth from '@/composables/useAuth.js'

import { ref } from 'vue'
export default {
  components: {
    'confirm-send-dialog': ConfirmSendDialog,
    'missing-email-dialog': MissingEmailDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { currentUser, loadCurrentUser } = useAuth()
    const isButtonVisible = ref(false)
    const confirmSendDialogRef = ref(null)
    const missingEmailDialogRef = ref(null)

    // Methods

    const onSendReportClick = () => {
      const userEmail = currentUser.value.email
      if (isString(userEmail) && userEmail.length > 0) {
        confirmSendDialogRef.value.openDialog({ userEmail, apiKey: props.apiKey })
      } else {
        missingEmailDialogRef.value.openDialog()
      }
    }

    loadCurrentUser().then(() => (isButtonVisible.value = true))

    return {
      confirmSendDialogRef,
      isButtonVisible,
      missingEmailDialogRef,
      onSendReportClick
    }
  }
}
</script>
