<template>
  <v-dialog
    v-model="dialog" width="350" persistent
    content-class="rounded-xl"
  >
    <v-card
      class="overflow-y-hidden popup-card"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="px-1">
        <v-row
          align="center" justify="center" no-gutters
          class="t-800-17"
        >
          {{ $t('company_broken_items.report.export_excel') }}
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 text-center t-500-16">
        {{ $t('company_broken_items.report.missing_email') }}
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="11" class="pb-5">
            <v-btn
              color="#0E4AFD" class="save-btn white--text" block
              depressed tile
              @click="closeDialog"
            >
              {{ $t('close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { isRtl } from '@/i18n'

import { ref } from 'vue'
export default {
  setup () {
    const dialog = ref(false)

    // Methods
    const openDialog = () => (dialog.value = true)
    const closeDialog = () => (dialog.value = false)

    return {
      dialog,
      isRtl,
      openDialog,
      closeDialog
    }
  }
}
</script>
